<template>
  <Modal
    id="modal_dates_livraison"
    :modal-class="rappel ? 'rappel' : undefined"
    :variant="rappel ? 'warning' : undefined"
    @before-open="handleBeforeOpen()"
    @change="!$event && reset()"
  >
    <template #title>
      {{ rappel ? $t("livraison.votre-livraison-de-rentree") : $t("livraison.choisir-mes-dates-livraison-souhaitees") }}
    </template>
    <template #subtitle>
      {{ $t("livraison.utiliser-form-specifier-besoins-particulier") }}
    </template>
    <template #content>
      <!-- Rappel -->
      <template v-if="rappel">
        <section>
          <p>{{ $t("livraison.vous-n-avez-pas-defini-periode") }}</p>
          <p>{{ $t("livraison.ces-infos-necessaires-envoi-commandes") }}</p>
        </section>
      </template>

      <!-- Formulaire avec infos de livraison -->
      <template v-else>
        <section
          v-if="maitreSite.infos.date_demande_livraison"
          id="bloc_ets"
        >
          <figure class="flex-vcenter">
            <img
              :src="require(`@lde/core_lde_vue/dist/assets/media/${typeEcole}`)"
              :alt="$t('general.alt-image-etablissement')"
            />
            <figcaption class="text-medium">
              {{ maitreSite.nom_complet }}
            </figcaption>
          </figure>
          <p class="text-regular">
            {{ $t("livraison.souhaits-exprimes", {
              nom: `${maitreSite.infos.user_demande_livraison.contact.prenom}
                    ${maitreSite.infos.user_demande_livraison.contact.nom}`,
              date: $moment(maitreSite.infos.date_demande_livraison).format($t("global.format-date")),
            }) }}
          </p>
        </section>
        <section id="bloc_dates">
          <h3 class="s5 title-separator">
            <span class="bold">{{ $t("livraison.vos-dates-souhaitees-livraison") }}</span>
          </h3>
          <div>
            <p
              v-html="$t('livraison.pour-toute-commande-passee-avant-date', {
                date: maitreSite.infos.date_max_commande_passee, })"
            />
            <ButtonToggle
              v-model="beforeHolidays"
              :label="beforeHolidays ? $t('general.oui') : $t('general.non')"
              type="switch"
              name="before_holidays"
              :disabled="maitreSite.infos.date_demande_livraison !== null"
            />
          </div>
          <div
            v-if="beforeHolidays"
            id="limite"
            class="flex-vcenter"
          >
            <p>{{ $t("livraison.indiquez-date-limite-livraison") }}</p>
            <InputClassic
              v-model="dates.maxDate"
              type="date"
              :label="$t('date.date-limite-avant-vacances')"
              :status="maitreSite.infos.date_demande_livraison !== null ? 'locked' : 'required'"
              :disabled="maitreSite.infos.date_demande_livraison !== null"
              :min="datesRange.limiteMin"
              :max="datesRange.limiteMax"
            >
              <template #label-icon>
                <UilCalendarAlt size="16" />
              </template>
              <template #input-icon>
                <IconDownTriangle />
              </template>
            </InputClassic>
            <p class="text-small">
              <UilExclamationCircle size="16" />
              {{ $t("livraison.seules-les-commandes-passees-dix-jours") }}
            </p>
          </div>
          <div id="dates">
            <p v-html="$t('livraison.pour-la-rentree-indiquez-periode')" />
            <div class="flex-vcenter">
              <InputClassic
                v-model="dates.start"
                type="date"
                :label="$t('date.du')"
                :status="maitreSite.infos.date_demande_livraison !== null ? 'locked' : 'required'"
                :min="datesRange.startMin"
                :max="datesRange.startMax"
              >
                <template #label-icon>
                  <UilCalendarAlt size="16" />
                </template>
                <template #input-icon>
                  <IconDownTriangle />
                </template>
              </InputClassic>
              <InputClassic
                v-model="dates.end"
                type="date"
                :label="$t('date.au')"
                :status="maitreSite.infos.date_demande_livraison !== null ? 'locked' : 'required'"
                :min="datesRange.endMin"
                :max="datesRange.endMax"
                :disabled="!dates.start"
              >
                <template #label-icon>
                  <UilCalendarAlt size="16" />
                </template>
                <template #input-icon>
                  <IconDownTriangle />
                </template>
              </InputClassic>
              <p class="text-small flex-vcenter">
                <UilExclamationCircle size="16" />
                {{ $t("livraison.dont-reliquats-avant-vacances") }}
              </p>
            </div>
          </div>
        </section>
        <template v-if="!maitreSite.infos.date_demande_livraison">
          <section id="bloc_contact">
            <h3 class="s5 title-separator">
              <span class="bold">{{ $t("livraison.votre-contact-pour-reception") }}</span>
            </h3>
            <p>{{ $t("livraison.renseignez-simplement-infos") }}</p>
            <div class="flex-vcenter">
              <InputClassic
                v-model="contact.nom_complet"
                :label="$t('general.nom-complet')"
                :placeholder="$t('general.nom-complet')"
                status="required"
              />
              <InputClassic
                v-model="contact.mail"
                type="email"
                :label="$t('general.email-professionnel')"
                :placeholder="$t('general.email-professionnel')"
                status="required"
              />
              <InputClassic
                v-model="contact.tel"
                type="tel"
                :label="$t('general.ligne-directe')"
                :placeholder="$t('general.ligne-directe')"
                status="required"
              />
            </div>
          </section>
          <section id="bloc_horaires">
            <h3 class="s5 title-separator">
              <span class="bold">{{ $t("livraison.vos-horaires-de-reception") }}</span>
            </h3>
            <p v-html="$t('livraison.vos-horaire-ouverture-aideront-transporteurs')" />
            <div class="flex-vcenter">
              <strong class="text-regular">{{ $t("general.le-matin") }}</strong>
              <SelectClassic
                v-model="hours.startAm"
                type="time"
                :label="$t('date.de')"
                :options="hoursRange.startAm"
                placeholder="-"
                status="required"
                inline
                options-top
              />
              <SelectClassic
                v-model="hours.endAm"
                type="time"
                :label="$t('date.a')"
                :options="hoursRange.endAm"
                placeholder="-"
                status="required"
                inline
                options-top
                :disabled="!hours.startAm"
              />
              <strong class="text-regular">{{ $t("general.et-apres-midi") }}</strong>
              <SelectClassic
                v-model="hours.startPm"
                type="time"
                :label="$t('date.de')"
                :options="hoursRange.startPm"
                placeholder="-"
                inline
                options-top
              />
              <SelectClassic
                v-model="hours.endPm"
                type="time"
                :label="$t('date.a')"
                :options="hoursRange.endPm"
                placeholder="-"
                inline
                options-top
                :disabled="!hours.startPm"
              />
            </div>
          </section>
        </template>
        <section
          v-else
          id="bloc_commentaire"
        >
          <h3 class="s5 title-separator">
            <span class="bold">{{ $t("livraison.vos-infos_pour-reception") }}</span>
          </h3>
          <InputClassic
            :value="maitreSite.infos.commentaire_transporteur"
            type="textarea"
            :label="$t('livraison.commentaire-pour-transporteur')"
            status="locked"
          />
        </section>
      </template>
    </template>
    <template #footer>
      <ButtonClassic
        v-if="rappel"
        variant="solid"
        :label="$t('livraison.remplir-mes-desiderata')"
        color="secondary"
        icon="right"
        @click="$emit('show-form')"
      >
        <template #right-icon>
          <UilArrowRight />
        </template>
      </ButtonClassic>
      <ButtonClassic
        v-else-if="maitreSite && !maitreSite.infos.date_demande_livraison"
        variant="solid"
        :label="$t('general.envoyer-mes-choix')"
        color="secondary"
        icon="right"
        :disabled="canNotSubmit"
        @click="submit()"
      >
        <template #right-icon>
          <UilMessage />
        </template>
      </ButtonClassic>
      <ButtonClassic
        v-else
        variant="solid"
        :label="$t('general.j-ai-compris')"
        color="secondary"
        icon="right"
        @click="$modal.hide('modal_dates_livraison')"
      >
        <template #right-icon>
          <UilThumbsUp />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import {
  Modal,
  ButtonToggle,
  ButtonClassic,
  InputClassic,
  SelectClassic,
  IconDownTriangle,
} from "@lde/core_lde_vue";

import {
  UilCalendarAlt,
  UilExclamationCircle,
  UilArrowRight,
  UilMessage,
  UilThumbsUp,
} from "@iconscout/vue-unicons";

import { confirmOrganismeOnTesting } from "@/modules/utils";

import Api from "@/modules/axios";

/**
 * Modale permettant de choisir les dates de livraison souhaitées ou d'effectuer un rappel à ce sujet.
 */
export default {
  name: "ModalDatesLivraison",
  components: {
    Modal,
    ButtonToggle,
    ButtonClassic,
    InputClassic,
    SelectClassic,
    IconDownTriangle,
    UilCalendarAlt,
    UilExclamationCircle,
    UilArrowRight,
    UilMessage,
    UilThumbsUp,
  },
  props: {
    /**
     * Affiche un contenu différent pour rappeler que les infos de livraison ne sont pas remplies.
     */
    rappel: {
      type: Boolean,
      default: false,
    },
    /**
     * Organisme maître site.
     */
    maitreSite: {
      type: Object,
      default: null,
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton de la variante de rappel.
     */
    "show-form",
    /**
     * Déclenché juste avant l'ouverture de la modale.
     */
    "before-open",
    /**
     * Déclenché lors de la validation du formulaire
     */
    "submit",
  ],
  data() {
    return {
      imgHasError: false,
      toggleHolidays: false,
      dates: {
        maxDate: null,
        start: null,
        end: null,
      },
      contact: {
        nom_complet: null,
        mail: null,
        tel: null,
      },
      hours: {
        startAm: null,
        endAm: null,
        startPm: null,
        endPm: null,
      },
    };
  },
  computed: {
    beforeHolidays: {
      // Si on a déjà renseigné la modale, on met true si on a mis une date avant les vacances, sinon false
      // Sinon, on se base sur un booléen classique dans les data
      get() {
        if (this.maitreSite.infos.date_demande_livraison !== null) {
          return !!this.maitreSite.infos.date_limite_livraison_av_vacances;
        }
        return this.toggleHolidays;
      },
      set(newVal) {
        this.toggleHolidays = newVal;
      },
    },
    typeEcole() {
      const primaires = ["EE", "EEA", "EM"];

      const colleges = ["CEG SPE", "CLG"];

      const lycees = [
        "MFR",
        "LEGT",
        "LG",
        "LT et LP",
        "LT",
        "EREA",
        "LP et LEGT",
        "LEG",
        "LYC",
        "LEGTPA",
        "L. AG",
        "EXP",
        "LP-LGT",
        "LEGTA",
        "LP - METIER",
        "LG",
        "LPO - METIER",
        "SEP",
        "LGT-LP",
        "EPLEFPA",
        "LP-GT",
        "ERA",
        "LP",
        "LCL",
        "CFA",
        "LPA",
        "LPO",
        "LGT",
      ];

      if (primaires.includes(this.maitreSite.infos.sigle)) {
        return "etablissements/primaire.svg";
      }
      if (colleges.includes(this.maitreSite.infos.sigle)) {
        return "etablissements/college.svg";
      }
      if (lycees.includes(this.maitreSite.infos.sigle)) {
        return "etablissements/lycee.svg";
      }
      return "missing_img/no_img_image.svg";
    },
    datesRange() {
      const year = this.$moment().year();
      const periodeLimite = {
        start: `${year}-04-01`,
        end: `${year}-07-12`,
      };
      const periodeRentree = {
        start: `${year}-08-01`,
        end: `${year}-09-30`,
      };

      return {
        limiteMin: periodeLimite.start,
        limiteMax: periodeLimite.end,
        startMin: periodeRentree.start,
        startMax: this.$moment(periodeRentree.end).subtract(2, "days").format("YYYY-MM-DD"),
        endMin: this.$moment(this.dates.start).add(2, "days").format("YYYY-MM-DD"),
        endMax: periodeRentree.end,
      };
    },
    hoursRange() {
      const ranges = {
        Am: {
          min: 6,
          max: 13.5,
          startRange: [],
          endRange: [],
        },
        Pm: {
          min: 12,
          max: 20,
          startRange: [],
          endRange: [],
        },
      };

      Object.keys(ranges).forEach((key) => {
        for (let i = ranges[key].min; i <= (ranges[key].max); i += 0.5) {
          const half = i % 1 === 0.5;

          // Heures de départ
          ranges[key].startRange.push({
            value: `${Math.floor(i)}:${half ? "30" : "00"}`,
            label: `${Math.floor(i)}:${half ? "30" : "00"}`,
          });
        }

        const startKey = `start${key}`; // On passe par une variable sinon le linter plante
        const index = ranges[key].startRange.findIndex((hour) => hour.value === this.hours[startKey]?.value);
        // 6 car six horaires (3 heures avec les demi heures)
        ranges[key].endRange = ranges[key].startRange.slice(index + 6);
      });

      return {
        startAm: ranges.Am.startRange.slice(0, -6),
        endAm: ranges.Am.endRange,
        startPm: ranges.Pm.startRange.slice(0, -6),
        endPm: ranges.Pm.endRange,
      };
    },
    canNotSubmit() {
      return (
        // Si un opérateur se fait passer pour un client (impersonate)
        this.isImpersonating
        // Si on choisit une livraison avant les vacances sans date limite
        || (this.beforeHolidays && !this.dates.maxDate)
        // S'il manque une date à la période de livraison
        || !this.dates.start || !this.dates.end
        // S'il manque une info concernant le contact
        || !this.contact.nom_complet || !this.contact.mail || !this.contact.tel
        // Si tous les horaires sont manquants
        || (!this.hours.startAm && !this.hours.endAm && !this.hours.startPm && !this.hours.endPm)
        // S'il manque un horaire aux plages de livraison (début sans fin ou vice versa, pour matin et après-midi)
        || (this.hours.startAm && !this.hours.endAm) || (!this.hours.startAm && this.hours.endAm)
        || (this.hours.startPm && !this.hours.endPm) || (!this.hours.startPm && this.hours.endPm)
      );
    },
  },
  methods: {
    /**
     * À l'ouverture, définit les dates si la modale a déjà été remplie.
     * On ne peut pas utiliser mounted() car la modale est déjà définie dans le composant parent.
     */
    handleBeforeOpen() {
      this.$emit("at-open");
      if (this.maitreSite?.infos.date_demande_livraison) {
        const infos = this.maitreSite.infos;
        this.dates = {
          start: this.$moment(infos.date_debut_livraison_rentree).format("YYYY-MM-DD"),
          end: this.$moment(infos.date_fin_livraison_rentree).format("YYYY-MM-DD"),
          maxDate: this.$moment(infos.date_limite_livraison_av_vacances).format("YYYY-MM-DD"),
        };
      }
    },
    /**
     * Compte le nombre de jours ouvrés entre deux dates.
     * @param {String} start Date de début.
     * @param {String} end Date de fin.
     */
    compterJoursOuvres(start, end) {
      let totalJoursOuvres = 0;
      let currentDate = this.$moment(start);

      while (currentDate <= this.$moment(end)) {
        // Si le jour n'est pas un week-end
        if (currentDate.isoWeekday() !== 6 && currentDate.isoWeekday() !== 7) {
          totalJoursOuvres += 1;
        }
        currentDate = currentDate.add(1, "days");
      }
      return totalJoursOuvres;
    },
    /**
     * Valide les informations de livraison de la modale.
     */
    submit() {
      if (confirmOrganismeOnTesting()) {
        const dates = [this.dates.maxDate, this.dates.start, this.dates.end];

        if (dates.find((date) => [0, 6].includes(this.$moment(date).day()))) {
          // On vérifie qu'aucun samedi ou dimanche n'est sélectionné
          this.$toast.error({ title: this.$t("livraison.impossible-de-selectionner-weekends") });
        } else if (this.dates.start >= this.dates.end) {
          // On vérifie que la date de début est bien antérieure à la date de fin
          this.$toast.error({ title: this.$t("livraison.date-fin-livraison-doit-etre-plus-recente") });
        } else if (
          // Si les dates ne sont pas dans les bonnes périodes
          (
            this.dates?.maxDate
            && !this.$moment(this.dates.maxDate).isBetween(
              this.datesRange.limiteMin, this.datesRange.limiteMax, "days", "[]",
            )
          )
          || !this.$moment(this.dates.start).isBetween(this.datesRange.startMin, this.datesRange.startMax, "days", "[]")
          || !this.$moment(this.dates.end).isBetween(this.datesRange.endMin, this.datesRange.endMax, "days", "[]")
        ) {
          this.$toast.error({ title: this.$t("livraison.veuillez-selectionner-date-dans-periode") });
        } else if (this.compterJoursOuvres(this.dates.start, this.dates.end) < 3) {
          // On vérifie qu'on a bien 3 jours ouvrés minimum dans la période sélectionnée
          this.$toast.error({ title: this.$tc("livraison.periode-livraison-x-jours-ouvres", 3, { nb: 3 }) });
        } else {
          // Le SelectClassic possède un format spécifique dont on n'a pas besoin pour le back
          const formattedHours = {
            startAm: this.hours.startAm?.value,
            endAm: this.hours.endAm?.value,
            startPm: this.hours.startPm?.value,
            endPm: this.hours.endPm?.value,
          };

          // Au cas où on aurait choisit une date puis mis "non" pour la livraison avant les vacances
          if (!this.beforeHolidays) {
            this.dates.maxDate = null;
          }

          const data = {
            contact: this.contact,
            dates: this.dates,
            heures: formattedHours,
          };

          Api().post(`/organisme/${this.maitreSite.id}/infos_livraison/`, data)
            .then(() => {
              this.$modal.hide("modal_dates_livraison");
              this.$toast.success({ title: this.$t("toast.vos-choix-bien-ete-enregistres") });
              this.$emit("submit", data);
            })
            .catch((err) => {
              this.$toast.error({ title: err.response.data });
            });
        }
      }
    },
    /**
     * Réinitialise tous les champs.
     */
    reset() {
      this.beforeHolidays = false;

      this.dates = {
        maxDate: null,
        start: null,
        end: null,
      };

      this.contact = {
        nom_complet: null,
        mail: null,
        tel: null,
      };

      this.hours = {
        startAm: null,
        endAm: null,
        startPm: null,
        endPm: null,
      };
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_dates_livraison.scss";
</style>
